import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ShoutAnswer, ShoutLikeAnswer } from '../model/shout';

@Injectable({
  providedIn: 'root'
})
export class ShoutService {

  constructor(private httpClient:HttpClient) { }

  public countShouts(token:string):Observable<ShoutAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.httpClient.get<ShoutAnswer>(environment.API_BASE+"/items/shouts?meta=*&limit=1",{headers: authHeaders});
  }

  public countShoutLikes(token:string):Observable<ShoutLikeAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.httpClient.get<ShoutLikeAnswer>(environment.API_BASE+"/items/shout_like?meta=*&limit=1",{headers: authHeaders});
  }

  public getShoutsForPeriodAndUser(begin:string,end:string,userId:number,token:string):Observable<ShoutAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    let url = environment.API_BASE+"/items/shouts?filter[owner][eq]="+userId;
    return this.httpClient.get<ShoutAnswer>(url,{headers: authHeaders});
  }

  private getAuthorizationHeader(token): HttpHeaders {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
  }
}
