import { Component, OnInit } from '@angular/core';
import { EchoAnswer } from '../model/echo';
import { ShoutAnswer, ShoutLikeAnswer } from '../model/shout';
import { UserAnswer } from '../model/user';
import { EchoService } from '../services/echo.service';
import { ShoutService } from '../services/shout.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public totalUsers:number;
  public totalShouts:number;
  public totalEchos:number;
  public totalShoutLikes:number;

  constructor(
    private userService:UserService,
    private shoutService:ShoutService,
    private echoService:EchoService
  ) { 
    
  }

  ngOnInit(): void {
    this.loadUserInfos();
    this.loadShoutInfos();
    this.loadEchoInfos();
  }
  private loadUserInfos(){
    let token = localStorage.getItem('token');
    this.userService.countUsers(token).subscribe((result:UserAnswer)=>{
      this.totalUsers = result.meta.total_count;
    },error=>{
      console.log(error);
    })
  }

  private loadShoutInfos(){
    let token = localStorage.getItem('token');
    this.shoutService.countShouts(token).subscribe((result:ShoutAnswer)=>{
      this.totalShouts = result.meta.total_count;
    },error=>{
      console.log(error);
    })

    this.shoutService.countShoutLikes(token).subscribe((result:ShoutLikeAnswer)=>{
      this.totalShoutLikes = result.meta.total_count;
    },error=>{
      console.log(error);
    })
  }

  private loadEchoInfos(){
    let token = localStorage.getItem('token');
    this.echoService.countEchos(token).subscribe((result:EchoAnswer)=>{
      this.totalEchos = result.meta.total_count;
    },error=>{
      console.log(error);
    })
  }

}
