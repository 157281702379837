import { Component, OnInit } from '@angular/core';
import { User, UserAnswer, UserData } from '../model/user';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.css']
})
export class EmployeesComponent implements OnInit {

  public shouters:User[];
  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.loadShouters();
  }

  private loadShouters(){
    let token = localStorage.getItem('token');
    this.userService.getAllShouter(token).subscribe((result:UserAnswer)=>{
      console.log(result);
      this.shouters = result.data;
      console.log(this.shouters);
    },error=>{

    });
  }
}
