import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginUser,LoginAnswer, UserAnswer } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient:HttpClient) { }
  public login(user:LoginUser):Observable<LoginAnswer>{
    return this.httpClient.post<LoginAnswer>(environment.API_BASE+"/auth/authenticate",user);
  }

  public countUsers(token:string){
    let authHeaders = this.getAuthorizationHeader(token);
    return this.httpClient.get<UserAnswer>(environment.API_BASE+"/users?meta=*&limit=1",{headers: authHeaders});
  }

  public getAllShouter(token:string):Observable<UserAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.httpClient.get<UserAnswer>(environment.API_BASE+"/users?filter[role][eq]=6",{headers: authHeaders});
  }
  public getShouter(id:number,token:string):Observable<UserAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.httpClient.get<UserAnswer>(environment.API_BASE+"/users?filter[id][eq]="+id+"&fields=*,avatar.*",{headers: authHeaders});
  }

  private getAuthorizationHeader(token): HttpHeaders {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
  }
}
