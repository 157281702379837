<div class="wrapper ">
    <div class="sidebar" data-color="purple" data-background-color="white">
      <div class="logo">
        <a href="http://www.creative-tim.com" class="simple-text logo-normal">
          Shouts
        </a>
      </div>
      <div class="sidebar-wrapper">
        <ul class="nav">
          <li class="nav-item active">
            <a class="nav-link" [routerLink]="['/']">
              <p>Dashboard</p>
            </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" [routerLink]="['/employees']">
              <p>Employees</p>
            </a>
          </li>
          <!-- your sidebar here -->
        </ul>
      </div>
    </div>
    <div class="main-panel">
      <!-- Navbar -->
      <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
        <div class="container-fluid">
          <div class="navbar-wrapper">
            <a class="navbar-brand" href="javascript:;">Employees</a>
          </div>
          <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-end">
            <ul class="navbar-nav">
              <li class="nav-item">
                
              </li>
              <!-- your navbar here -->
            </ul>
          </div>
        </div>
      </nav>
      <!-- End Navbar -->
      <div class="content">
        <div class="container-fluid">
          <!-- your content here -->

          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header card-header-primary">
                  <h4 class="card-title ">Employees: Shouter</h4>
                  <p class="card-category">An employee who is paid for number of shouts</p>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table">
                      <thead class=" text-primary">
                        <th>
                          ID
                        </th>
                        <th>
                          Name
                        </th>
                        <th>
                            Surename
                          </th>
                          <th>
                            Email
                          </th>
                        <th>
                          Cent per Shout
                        </th>
                        <th>
                          Timezone
                        </th>
                        <th>
                            Visit
                        </th>
                      </thead>
                      <tbody>
                        <tr *ngFor="let shouter of shouters">
                          <td>
                            {{shouter.id}}
                          </td>
                          <td>
                            {{shouter.first_name}}
                          </td>
                          <td>
                            {{shouter.last_name}}
                        </td>
                        <td>
                            {{shouter.email}}
                        </td>
                          <td>
                            0.3 Cent
                          </td>
                          <td>
                            {{shouter.timezone}}
                          </td>
                          <td class="text-primary">
                            <a [routerLink]="['/employees',shouter.id]">Check out</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            </div>
          
        </div>
      </div>
      <footer class="footer">
        <div class="container-fluid">
          <nav class="float-left">
            <ul>
              <li>
                <a href="https://shouts.ch">
                  Shouts
                </a>
              </li>
            </ul>
          </nav>
          <div class="copyright float-right">
          </div>
          <!-- your footer here -->
        </div>
      </footer>
    </div>
  </div>