<div class="wrapper ">
    <div class="sidebar" data-color="purple" data-background-color="white">
      <div class="logo">
        <a href="http://www.creative-tim.com" class="simple-text logo-normal">
          Shouts
        </a>
      </div>
      <div class="sidebar-wrapper">
        <ul class="nav">
          <li class="nav-item active">
            <a class="nav-link" [routerLink]="['/']">
              <p>Dashboard</p>
            </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" [routerLink]="['/employees']">
              <p>Employees</p>
            </a>
          </li>
          <!-- your sidebar here -->
        </ul>
      </div>
    </div>
    <div class="main-panel">
      <!-- Navbar -->
      <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
        <div class="container-fluid">
          <div class="navbar-wrapper">
            <a class="navbar-brand" href="javascript:;">Shouter Payment</a>
          </div>
          <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index"
            aria-expanded="false" aria-label="Toggle navigation">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-end">
            <ul class="navbar-nav">
              <li class="nav-item">
  
              </li>
              <!-- your navbar here -->
            </ul>
          </div>
        </div>
      </nav>
      <!-- End Navbar -->
      <div class="content">
        <div class="container-fluid">
          <!-- your content here -->
          <div class="row">
            <div class="col-md-6">
                <div class="card">
                  <div class="card-header card-header-primary">
                    <h4 class="card-title ">Shouter: Shouts</h4>
                    <p class="card-category">All shouts</p>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table">
                        <thead class=" text-primary">
                          <th>
                              ID
                            </th>
                            <th>
                              Content
                            </th>
                            <th>
                              Show Echos
                            </th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let shout of shouts">
                              <td>
                                  {{shout.id}}
                                </td>
                                <td>
                                  {{shout.content}}
                                </td>
                                <td>
                                  <a href="javascript:;" class="btn btn-primary btn-round" (click)="showEchos(shout)">Show Echos<div class="ripple-container"></div></a>
                                </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                
            </div>
            <div class="col-md-6">
                <div class="card">
                  <div class="card-header card-header-primary">
                    <h4 class="card-title ">Echos for Shout</h4>
                    <p class="card-category">The echos</p>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table">
                        <thead class=" text-primary">
                          <th>
                              ID
                            </th>
                            <th>
                              Content
                            </th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let echo of echos">
                              <td>
                                  {{echo.id}}
                                </td>
                                <td>
                                  {{echo.content}}
                                </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                
            </div>
          </div>
          
        </div>
      </div>
      <footer class="footer">
        <div class="container-fluid">
          <nav class="float-left">
            <ul>
              <li>
                <a href="https://shouts.ch">
                  Shouts
                </a>
              </li>
            </ul>
          </nav>
          <div class="copyright float-right">
          </div>
          <!-- your footer here -->
        </div>
      </footer>
    </div>
  </div>
  