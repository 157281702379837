import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShouterPayment, ShouterPaymentAnswer } from '../model/shouter';
import { User, UserAnswer } from '../model/user';
import { ShouterReportService } from '../services/shouter-report.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {

  private employeeId:number;
  public user:User;
  public shouterPayments:ShouterPayment[];
  constructor(
    private activatedRoute:ActivatedRoute,
    private userService: UserService,
    private shouterReportService:ShouterReportService
  ) {
    this.employeeId = Number(this.activatedRoute.snapshot.paramMap.get('id'));
    console.log(this.employeeId);
  }

  ngOnInit(): void {
    this.loadShouter();
    this.loadShouterReport();
  }
  
  private loadShouter(){
    let token = localStorage.getItem('token');
    this.userService.getShouter(this.employeeId,token).subscribe((result:UserAnswer)=>{
      this.user = result.data[0];
    },  
    error=>{
      console.log(error);
    });
  }

  private loadShouterReport(){
    let token = localStorage.getItem('token');
    this.shouterReportService.getShouterReports(this.employeeId,token).subscribe((result:ShouterPaymentAnswer)=>{
      this.shouterPayments = result.data;
      console.log(this.shouterPayments);
      this.calculateShoutEchoRatio();
    },  
    error=>{
      console.log(error);
    });
  }

  private calculateShoutEchoRatio(){
    this.shouterPayments.forEach((shouterPayment:ShouterPayment)=>{
      if(shouterPayment.echos==0 || shouterPayment.shouts==0){
        shouterPayment.shoutEchoRatio = 0;
        return;
      }
      let ratio = shouterPayment.echos/ shouterPayment.shouts;
      if(ratio>=1){
        ratio = 1;
      }
      shouterPayment.shoutEchoRatio = ratio;
    });
  }

}
