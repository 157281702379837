<div class="wrapper ">
  <div class="sidebar" data-color="purple" data-background-color="white">
    <div class="logo">
      <a href="http://www.creative-tim.com" class="simple-text logo-normal">
        Shouts
      </a>
    </div>
    <div class="sidebar-wrapper">
      <ul class="nav">
        <li class="nav-item active">
          <a class="nav-link" [routerLink]="['/']">
            <p>Dashboard</p>
          </a>
        </li>
        <li class="nav-item active">
          <a class="nav-link" [routerLink]="['/employees']">
            <p>Employees</p>
          </a>
        </li>
        <!-- your sidebar here -->
      </ul>
    </div>
  </div>
  <div class="main-panel">
    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
      <div class="container-fluid">
        <div class="navbar-wrapper">
          <a class="navbar-brand" href="javascript:;">Employees</a>
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index"
          aria-expanded="false" aria-label="Toggle navigation">
          <span class="sr-only">Toggle navigation</span>
          <span class="navbar-toggler-icon icon-bar"></span>
          <span class="navbar-toggler-icon icon-bar"></span>
          <span class="navbar-toggler-icon icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end">
          <ul class="navbar-nav">
            <li class="nav-item">

            </li>
            <!-- your navbar here -->
          </ul>
        </div>
      </div>
    </nav>
    <!-- End Navbar -->
    <div class="content">
      <div class="container-fluid">
        <!-- your content here -->

        <div class="row">
          <div class="col-md-8">
            <div class="card">
              <div class="card-header card-header-primary">
                <h4 class="card-title">Profile</h4>
                <p class="card-category">The Profile</p>
              </div>
              <div class="card-body">
                <form>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="bmd-label-floating">Email address</label>
                        <br />
                        {{user?.email}}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="bmd-label-floating">Fist Name</label>
                        <br />
                        {{user?.first_name}}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="bmd-label-floating">Last Name</label>
                        <br />
                        {{user?.last_name}}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="bmd-label-floating">Adress</label>
                        <br />
                        {{user?.timezone}}
                      </div>
                    </div>
                  </div>

                  <div class="clearfix"></div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card card-profile">
              <div class="card-avatar">
                <a href="javascript:;">
                  <img class="img" src="{{user?.avatar?.data?.full_url}}" />
                </a>
              </div>
              <div class="card-body">
                <h6 class="card-category text-gray">Shouter</h6>
                <h4 class="card-title">{{user?.first_name}} {{user?.last_name}}</h4>
                <p class="card-description">
                  A shouter
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header card-header-primary">
                <h4 class="card-title ">Shouter: Payment</h4>
                <p class="card-category">All payments of a shouter</p>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table">
                    <thead class=" text-primary">
                      <th>
                        ID
                      </th>
                      <th>
                        Number of Shouts
                      </th>
                      <th>
                        Number of Echos
                      </th>
                      <th>
                        Cent per Shout
                      </th>
                      <th>
                        Shout Echo Ratio
                      </th>
                      <th>
                        Payout
                      </th>
                      <th>
                        Month
                      </th>
                      <th>
                        Payed
                      </th>
                      <th>
                        Inspect
                      </th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let shouterPayment of shouterPayments">
                        <td>
                          {{shouterPayment.id}}
                        </td>
                        <td>
                          {{shouterPayment.shouts}}
                        </td>
                        <td>
                          {{shouterPayment.echos}}
                        </td>
                        <td>
                          {{shouterPayment.cent_per_shout}}
                        </td>
                        <td>
                          {{shouterPayment.shoutEchoRatio}}
                        </td>
                        <td>
                          {{shouterPayment.cent_per_shout * (shouterPayment.shouts+shouterPayment.echos)/100}} $
                        </td>
                        <td>
                          {{shouterPayment.month}}
                        </td>
                        <td>
                          {{shouterPayment.payed}}
                        </td>
                        <td>
                          <a href="javascript:;" class="btn btn-primary btn-round" [routerLink]="['/shouter-payment/',shouterPayment.id]">Inspect<div class="ripple-container"></div></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <footer class="footer">
      <div class="container-fluid">
        <nav class="float-left">
          <ul>
            <li>
              <a href="https://shouts.ch">
                Shouts
              </a>
            </li>
          </ul>
        </nav>
        <div class="copyright float-right">
        </div>
        <!-- your footer here -->
      </div>
    </footer>
  </div>
</div>
