import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Echo, EchoAnswer } from '../model/echo';
import { Shout, ShoutAnswer } from '../model/shout';
import { ShouterPayment, ShouterPaymentOneAnswer } from '../model/shouter';
import { EchoService } from '../services/echo.service';
import { ShoutService } from '../services/shout.service';
import { ShouterReportService } from '../services/shouter-report.service';

@Component({
  selector: 'app-shouter-payment',
  templateUrl: './shouter-payment.component.html',
  styleUrls: ['./shouter-payment.component.css']
})
export class ShouterPaymentComponent implements OnInit {

  public shouts:Shout[];
  echos:Echo[];
  public shouterReportId:number;
  public shouterPayment:ShouterPayment;
  constructor(
    private activatedRoute:ActivatedRoute,
    private shouterReportService:ShouterReportService,
    private shoutService:ShoutService,
    private echoService:EchoService
    ) {
    this.shouterReportId = Number(this.activatedRoute.snapshot.paramMap.get('id'));
  }

  ngOnInit(): void {
    this.loadShouterReport();
  }

  private loadShouterReport(){
    let token = localStorage.getItem('token');
    this.shouterReportService.getShouterReport(this.shouterReportId,token).subscribe((shouterReport:ShouterPaymentOneAnswer)=>{
      this.shouterPayment = shouterReport.data;
      console.log(this.shouterPayment);
      this.loadShoutsForUserInTimeRange();
    });
  }

  private loadShoutsForUserInTimeRange(){
    let token = localStorage.getItem('token');
    this.shoutService.getShoutsForPeriodAndUser("","",this.shouterPayment.shouter, token).subscribe((shouts:ShoutAnswer)=>{
      this.shouts = shouts.data;
      console.log(this.shouts);
    });
  }

  public showEchos(shout:Shout){
    let token = localStorage.getItem('token');
    this.echoService.loadEchosForShout(shout.id,token).subscribe((echos:EchoAnswer)=>{
      this.echos = echos.data;
    });
  }

}
