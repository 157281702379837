import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginAnswer, LoginUser } from '../model/user';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public loginUser:LoginUser;
  constructor(
    private userService:UserService,
    private router:Router
    ) {
    this.loginUser = new LoginUser();
  }

  ngOnInit(): void {
    
  }

  public login(){
    this.userService.login(this.loginUser).subscribe((result:LoginAnswer) =>{
      this.parseLoginData(result);
      this.router.navigate(["/"]);
    }, error =>{
      //TODO: Error handling
      console.log(error);
    })
  }

  private parseLoginData(loginData:LoginAnswer){
    localStorage.setItem('token', loginData.data.token);
    localStorage.setItem('user_id', loginData.data.user.id.toString());
  }
  private showError(error:string){
    alert(error);
  }


}
