import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EchoAnswer } from '../model/echo';

@Injectable({
  providedIn: 'root'
})
export class EchoService {

  constructor(private httpClient:HttpClient) { }

  public countEchos(token:string):Observable<EchoAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.httpClient.get<EchoAnswer>(environment.API_BASE+"/items/echos?meta=*&limit=1",{headers: authHeaders});
  }
  public loadEchosForShout(shoutId:number,token:string):Observable<EchoAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.httpClient.get<EchoAnswer>(environment.API_BASE+"/items/echos?filter[shout][eq]="+shoutId,{headers: authHeaders});
  }

  private getAuthorizationHeader(token): HttpHeaders {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
  }
}
